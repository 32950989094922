import Request from './Request';

class SupportApi {
  //// CONTACT US ////
  static sendContactUsInteractionWithAuth(body) {
    console.warn('API | POST | Contact Us Interaction (Auth)');
    return Request.POST_json(`${process.env.supportAPI}/v1/contact-us/submit`, body, global.gpAccessToken);
  }
  static sendContactUsInteractionWithoutAuth(body) {
    console.warn('API | POST | Contact Us Interaction (No Auth)');
    return Request.POST_json(`${process.env.supportAPI}/v1/contact-us/submit`, body, null);
  }
  //// FEEDBACK ////
  static sendFeedbackInteractionWithAuth(body) {
    console.warn('API | POST | Feedback Interaction (Auth)');
    return Request.POST_json(`${process.env.supportAPI}/v1/feedback/submit`, body, global.gpAccessToken);
  }
  static sendFeedbackInteractionWithoutAuth(body) {
    console.warn('API | POST | Feedback Interaction (No Auth)');
    return Request.POST_json(`${process.env.supportAPI}/v1/feedback/submit`, body, null);
  }

  //// CREATE SUPPORT CASE ////
  static createSupportCaseWithAuth(body) {
    console.warn('API | POST | Support Case Interaction (Auth)');
    return Request.POST_json(`${process.env.supportAPI}/v1/case/submit`, body, global.gpAccessToken);
  }
  static createSupportCaseWithoutAuth(body) {
    console.warn('API | POST | Support Case Interaction (No Auth)');
    return Request.POST_json(`${process.env.supportAPI}/v1/case/submit`, body, null);
  }

  //// SUPPORT QUEUE ////
  static syncSupportQueue(body) {
    console.warn('API | POST | Sync Support Queue');
    return Request.POST_json(`${process.env.supportAPI}/v1/cases/active`, body, global.gpAccessToken);
  }


}

export default SupportApi;

